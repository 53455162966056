import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "new-help-files-available"
    }}>{`New Help Files Available`}</h1>
    <hr></hr>
    <p>{`New help files are now available to guide you through features, configurations, and tools for Solus's suite of products. Visit our `}<a parentName="p" {...{
        "href": "https://help.sol.us/#/"
      }}>{`Support Hub`}</a>{` to explore these resources and streamline your workflows!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      